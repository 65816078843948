export type ButtonCategory =
  | 'primary'
  | 'secondary'
  | 'translucent'
  | 'primary-translucent'
  | 'secondary-translucent';

export type ButtonSize = 'xs' | 'sm' | 'lg';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string | undefined;
  className?: string;
  category?: ButtonCategory;
  size?: ButtonSize;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

export const getCategoryClassNames = (category: ButtonCategory) => {
  switch (category) {
    case 'primary':
      return 'bg-grayscale-700 backdrop-blur-lg text-grayscale-300 enabled:hover:bg-[#343D4A] disabled:bg-grayscale-800 disabled:text-grayscale-400';
    case 'secondary':
      return 'bg-grayscale-300 backdrop-blur-md text-grayscale-600 hover:bg-[#BABBBC] disabled:bg-grayscale-500 disabled:text-grayscale-400';
    case 'translucent':
      return 'bg-grayscale-300 bg-opacity-10 backdrop-blur-md text-grayscale-300 hover:bg-opacity-20';
    case 'primary-translucent':
      return 'bg-[#151C26] bg-opacity-80 backdrop-blur-lg hover:bg-[#1F242C] hover:bg-opacity-85 hover:backdrop-blur-lg hover:bg-opacity-100';
    case 'secondary-translucent':
      return 'bg-grayscale-300 bg-opacity-20 backdrop-blur-md text-white hover:bg-opacity-20';
    default:
      return '';
  }
};

export const getSizeClassNames = (size: ButtonSize) => {
  switch (size) {
    case 'xs':
      return 'px-4 py-3 text-sm-button';
    case 'sm':
      return 'px-6 py-5 text-sm-button';
    case 'lg':
      return 'px-8 py-6 text-lg-button';
    default:
      return '';
  }
};

const ButtonV2 = ({
  text,
  className = '',
  category = 'primary',
  size = 'sm',
  iconLeft = null,
  iconRight = null,
  ...props
}: ButtonProps) => {
  const buttonTypeClasses = getCategoryClassNames(category);
  const sizeClasses = getSizeClassNames(size);
  return (
    <button
      title={text}
      className={`flex items-center justify-center whitespace-nowrap rounded font-bold transition-all ${buttonTypeClasses} ${sizeClasses} ${className}`}
      onClick={props.onClick}
      disabled={props.disabled}
      {...props}
    >
      {iconLeft}
      {text}
      {iconRight}
    </button>
  );
};
export default ButtonV2;
